import { createReactLifecycles } from 'single-spa-workfront'
import { App } from './App'
import { FiltersList } from './components/FiltersList'
import { GroupingsList } from './components/GroupingsList'
import { ViewsList } from './components/ViewsList'

const lifecycles = createReactLifecycles({
  rootComponent: App,
})

export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount

export const FiltersListComponent = FiltersList
export const ViewsListComponent = ViewsList
export const GroupingsListComponent = GroupingsList

export default App
